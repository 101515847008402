<template>
  <div class="d-flex flex-column text-center">
    <span class="text-overline ma-8">{{
      $vuetify.lang.t("$vuetify.worksheet.chooseLocation")
    }}</span>

    <v-autocomplete
      :label="$vuetify.lang.t('$vuetify.worksheet.location')"
      placeholder="Choose a location for inventory"
      v-model="locationId"
      :items="locations"
      item-text="name"
      item-value="id"
      clearable
      solo
      hide-details
    />

    <div class="d-flex justify-center mx-2 my-8">
      <v-btn :disabled="worksheet.status  == 'USED'" @click="updateWorksheet(worksheet)" color="primary">{{
        $vuetify.lang.t("$vuetify.base.update")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const VehicleSelect = () =>
  import("@/components/companyVehicle/VehicleSelect.vue");
import { getCompanyVehicle } from "@/services/companyVehicle";

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
    weightUnit: {
      type: String,
      required: false,
      default: 'Kg'
    }
  },
  data: () => {
    return {
      locationId: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("locations", ["locations"]),

    hasEntries() {
      return this.worksheet.entries && this.worksheet.entries.length > 0;
    },
  },
  components: {
    VehicleSelect,
  },
  created() {
    this.loadLocations();
    this.locationId = this.worksheet.locationId;
  },
  methods: {
    ...mapActions("processWorksheet", [
      "updateProcessWorksheet",
      "deleteProcessWorksheet",
    ]),
    ...mapActions("messages", ["addErrorMessage", "addSuccessMessage"]),
    ...mapActions("locations", ["loadLocations"]),


    updateWorksheet(worksheet) {
      this.worksheet.locationId = this.locationId;

      const payload = Object.assign({}, worksheet);
      delete payload.files;
      this.updateProcessWorksheet(payload);

      this.$emit("update:worksheet", worksheet);
      this.$emit("updated", worksheet);
    },
    
  },
};
</script>

<style></style>
